import React from 'react';
import { View, Text, Button, FlatList } from 'react-native';

// Mock data for the cart
const cartItems = [
  { id: '1', title: 'Item 1', price: 10 },
  { id: '2', title: 'Item 2', price: 20 },
  { id: '3', title: 'Item 3', price: 30 },
  // Add more items as needed
];

const CartScreen: React.FC = () => {
  return (
    <View>
      <Text>Your Cart</Text>
      <FlatList
        data={cartItems}
        keyExtractor={item => item.id}
        renderItem={({ item }) => (
          <View>
            <Text>{item.title}</Text>
            <Text>${item.price}</Text>
            {/* Add more details as needed */}
          </View>
        )}
      />
      <Button title="Checkout" onPress={() => { /* Handle checkout */ }} />
    </View>
  );
};

export default CartScreen;
