import { createStackNavigator } from "@react-navigation/stack"
import SignUpScreen from "../../screens/SignUpScreen";

const Stack = createStackNavigator()
const ProfileStack = () => {

  return (
    <Stack.Navigator initialRouteName="ProfileScreen">
      <Stack.Screen name="ProfileScreen" component={SignUpScreen} />
    </Stack.Navigator>
  )
}


export default ProfileStack;