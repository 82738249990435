import { createStackNavigator } from "@react-navigation/stack"
import PaymentScreen from "../../screens/PaymentScreen";

const Stack = createStackNavigator()
const PaymentStack = () => {

  return (
    <Stack.Navigator initialRouteName="PaymentScreen">
      <Stack.Screen name="PaymentScreen" component={PaymentScreen} />
    </Stack.Navigator>
  )
}


export default PaymentStack;