import { View, Text } from "react-native";
import React from "react";
import {
  BottomTabScreenProps,
  createBottomTabNavigator,
} from "@react-navigation/bottom-tabs";
import Icons from "@expo/vector-icons/MaterialIcons";
import { CompositeScreenProps } from "@react-navigation/native";
import { RootStackScreenProps } from "./RootNavigator";
import CustomBottomTabs from "../components/CustomBottomTabs";
import HomeStack from "./stacks/HomeStack";
import CartStack from "./stacks/CartStack";
import PaymentStack from "./stacks/PaymentStack";
import ProfileStack from "./stacks/ProfileStack";

export type TabsStackParamList = {
  Home: undefined;
  Cart: undefined;
  Payment: undefined;
  Profile: undefined;
};
const TabsStack = createBottomTabNavigator<TabsStackParamList>();

export type TabsStackScreenProps<T extends keyof TabsStackParamList> =
  CompositeScreenProps<
    BottomTabScreenProps<TabsStackParamList, T>,
    RootStackScreenProps<"TabsStack">
  >;

const TabsNavigator = () => {
  return (
      <TabsStack.Navigator
        screenOptions={{
          tabBarShowLabel: false,
        }}
        tabBar={(props) => <CustomBottomTabs {...props} />}
      >
        <TabsStack.Screen
          name="Home"
          component={HomeStack}
          options={{
            headerShown: false,
            tabBarIcon(props) {
              return <Icons name="home" {...props} />;
            },
          }}
        />
        <TabsStack.Screen
          name="Cart"
          component={CartStack}
          options={{
            tabBarIcon(props) {
              return <Icons name="shopping-cart" {...props} />;
            },
          }}
        />
        <TabsStack.Screen
          name="Payment"
          component={PaymentStack}
          options={{
            tabBarIcon(props) {
              return <Icons name="account-balance-wallet" {...props} />;
            },
          }}
        />
        <TabsStack.Screen
          name="Profile"
          component={ProfileStack}
          options={{
            tabBarIcon(props) {
              return <Icons name="person" {...props} />;
            },
          }}
        />
      </TabsStack.Navigator>
  );
};

export default TabsNavigator;