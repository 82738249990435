import React, { useCallback, useRef, useState } from 'react';
import { View, FlatList, StyleSheet, Text, Image, Dimensions, TouchableOpacity, ScrollView, SafeAreaView } from 'react-native';
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

import { useNavigation, useTheme } from '@react-navigation/native';
import yelp from '../api/yelp';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import HomePageMasonryList from '../components/HomePageMasonryList';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import CustomBackdrop from '../components/CustomBackdrop';
import FilterView from '../components/FilterView';

const { width: windowWidth, height: windowHeight } = Dimensions.get('window');

const AVATAR_URL = "https://images.unsplash.com/photo-1542295669297-4d352b042bca?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGRyZXNzfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60";
const CATEGORIES = [
  "Clothing",
  "Shoes",
  "Pants",
  "Purses",
  "Accessories",
  "Shops",
  "Merch"
]


const HomeScreen = () => {
  const { colors } = useTheme();
  const [categoryIndex, setCategoryIndex] = useState(0);
  const bottomSheetModalRef = useRef<BottomSheetModal>(null);


  const openFilterModal = useCallback(() => {
    bottomSheetModalRef.current?.present();
  }, []);


  const products = [
    {
      id: "1",
      title: 'Product 1',
      price: 99.99,
      image: 'https://media.istockphoto.com/id/1483968509/photo/orange-and-green-nike-football-shoes-hanging-from-the-power-line.webp?b=1&s=170667a&w=0&k=20&c=XyWPeOJoTCM-IxQdvbk-ax0WqBigLPGZLHuTvexf8fQ=',
      description: 'This is a brief description of the product.',
    },
    {
      id: "2",
      title: 'Product 2',
      price: 79.99,
      image: 'https://images.unsplash.com/photo-1460353581641-37baddab0fa2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNob2VzJTIwbmlrZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60',
      description: 'This is a brief description of the product.',
    },
    {
      id: "3",
      title: 'Product 3',
      price: 59.99,
      image: 'https://images.unsplash.com/photo-1515347619252-60a4bf4fff4f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8c2hvZXMlMjB3b21lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60',
      description: 'This is a brief description of the product.',
    },
    // more products...
  ];



  const carouselData = [
    // Replace with your own data
    { title: 'Slide 1', image: 'https://placeimg.com/640/480/any' },
    { title: 'Slide 2', image: 'https://placeimg.com/640/480/any' },
    { title: 'Slide 3', image: 'https://placeimg.com/640/480/any' },
  ];
  const [term, setTerm] = useState('');
  const [restaurants, setRestaurants] = useState<any>({});
  const [error, setErrorMessage] = useState<any>({});

  const { width: screenWidth } = Dimensions.get('window');
  const navigation = useNavigation();
  const renderItem = ({ item }: { item: any }) => {
    return (
      <TouchableOpacity style={styles.item} onPress={() => navigation.navigate('Detail', { item })} >
        <Image source={{ uri: item.image }} style={styles.productImage} />
        <Text style={styles.title}>{item.name}</Text>
        <Text style={styles.price}>${item.price}</Text>
      </TouchableOpacity>
    );
  };

  const searchApi = async () => {
    try {
      const response = await yelp.get('/search', {
        params: {
          limit: 50,
          term,
          location: 'miami'
        }
      });
      setRestaurants(response.data.businesses);
    } catch (errr) {
      setErrorMessage('Something went wrong in yelp api');
    }

  }
  // Define your GraphQL query
  const GET_PRODUCTS = gql`
  query GetProducts {
    listProducts {
      id
      name
      description
      price
      imageUrl
    }
  }
`;
  const { loading, error: productError, data } = useQuery(GET_PRODUCTS);
  return (
    <ScrollView>
      < SafeAreaView style={{ paddingVertical: 24, gap: 24 }}>
        {/* --- Notification Section BEGIN --- */}
        <View style={styles.notificationContainer}>
          <Image source={{
            uri: AVATAR_URL
          }} style={{ width: 52, aspectRatio: 1, borderRadius: 52 }} resizeMode='cover' />
          <View style={{ flex: 1 }}>
            <Text style={{ fontSize: 18, fontWeight: "600", marginBottom: 8 }} numberOfLines={1}>Bloom is here</Text>
            <Text style={{ color: colors.text, opacity: 0.5 }} numberOfLines={1}>Click to Discover Bloom Fashion</Text>
          </View>
          <TouchableOpacity style={{
            width: 52, aspectRatio: 1, alignItems: 'center', justifyContent: 'center', borderRadius: 52,
            borderWidth: 1, borderColor: colors.border
          }}>
            <MaterialIcons name="notifications" size={24} color={colors.text} />
          </TouchableOpacity>
        </View>
        {/* --- Notification Section END --- */}

        {/* Search Bar Section BEGIN */}
        <View style={{
          flexDirection: 'row', paddingHorizontal: 24, gap: 12
        }}>
          <TouchableOpacity style={{ flex: 1, height: 52, borderRadius: 52, borderWidth: 1, borderColor: colors.border, alignItems: 'center', flexDirection: "row", paddingHorizontal: 24, gap: 12 }}>
            <MaterialIcons name="search" size={24} color={colors.text} style={{ opacity: 0.5 }} />
            <Text style={{ flex: 1, fontSize: 16, color: colors.text, opacity: 0.5 }}></Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={openFilterModal}
            style={{
              width: 52,
              aspectRatio: 1,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 52,
              backgroundColor: colors.primary,
            }}>
            <MaterialIcons name="tune" size={24} color={colors.background} />
          </TouchableOpacity>
        </View>
        {/* Search Bar Section END */}

        {/* Grid Collection View BEGIN */}
        <View style={{ paddingHorizontal: 24 }}>
          {/* Title bar */}
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 12 }}>
            <Text style={{ fontSize: 22, fontWeight: "700" }}>New Collections</Text>
            <TouchableOpacity>
              <Text>See All</Text>
            </TouchableOpacity>
          </View>

          {/* GRID START */}
          <View style={{ flexDirection: 'row', height: 250, gap: 12 }}>
            {/* CARD START*/}
            < Card
              price={products[0].price}
              imageUrl={products[0].image}
              onPress={() => { }}
            />
            <View style={{ flex: 1, gap: 12 }}>
              < Card
                price={products[1].price}
                imageUrl={products[1].image}
                onPress={() => { }}
              />
              < Card
                price={products[2].price}
                imageUrl={products[2].image}
                onPress={() => { }}
              />
            </View>
            {/* CARD END*/}
          </View>
          {/* GRID END */}
        </View>
        {/* Grid Collection View END */}

        {/* Categories Section BEGIN */}
        <FlatList
          data={CATEGORIES}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{
            paddingHorizontal: 16, gap: 16,
          }}
          renderItem={({ item, index }) => {
            const isSelected = categoryIndex === index;
            return (
              <TouchableOpacity
                style={{
                  backgroundColor: isSelected ? colors.primary : colors.card,
                  paddingHorizontal: 24, paddingVertical: 16, borderRadius: 100,
                  borderWidth: isSelected ? 0 : 1,
                  borderColor: colors.border,
                }}
                onPress={() => {
                  return setCategoryIndex(index);
                }}
              >
                <Text
                  style={{
                    color: isSelected ? colors.background : colors.text,
                    fontWeight: "600",
                    fontSize: 16,
                    opacity: isSelected ? 1 : 0.5,
                  }}
                >{item}</Text>
              </TouchableOpacity>
            )
          }}
        />
        {/* Categories Section END */}

        {/* Mansonary List START */}
        <HomePageMasonryList />
        {/* Mansonary List END */}

        {/* BottomSheetModal START */}
        <BottomSheetModal
          snapPoints={['80']}
          index={0}
          ref={bottomSheetModalRef}
          backdropComponent={(props) => <CustomBackdrop {...props} />}
        >
          <FilterView />
        </BottomSheetModal>
        {/* BottomSheetModal END */}

      </SafeAreaView >
    </ScrollView >
  )
};

const styles = StyleSheet.create({
  notificationContainer: {
    paddingHorizontal: 24, flexDirection: "row", alignItems: 'center', gap: 8
  },
  viewContainer: {
    flex: 1,
    backgroundColor: '#f8f8f8', // Lighter background color
    paddingTop: windowHeight * 0.02,
  },
  listContainer: {
    padding: windowWidth * 0.05,
    background: `linear-gradient(to right, #ffffff 0%, #e6e6e6 100%)`
  },
  slide: {
    backgroundColor: 'white',
    borderRadius: 5,
    height: windowHeight * 0.3,
    padding: 15,
    marginLeft: windowWidth * 0.05,
    marginRight: windowWidth * 0.05,
  },
  image: {
    width: windowWidth * 0.8,
    height: windowHeight * 0.2,
  },
  productImage: {
    width: windowWidth * 0.4, // Bigger product image
    height: windowHeight * 0.2,
    marginBottom: 10,
    borderRadius: 15, // Rounded corners
  },
  spacer: {
    height: windowHeight * 0.02,  // Spacer added here
  },
  title: {
    fontSize: 18, // Smaller title
    fontWeight: '500', // Less bold title
    textAlign: 'center',
    marginTop: 10,
  },
  price: {
    fontSize: 16, // Smaller price
    color: '#888',
    textAlign: 'center',
    marginTop: 10,
  },
  item: {
    backgroundColor: '#FFFFFF',
    padding: 20,
    marginVertical: windowHeight * 0.02, // More space between items
    marginHorizontal: windowWidth * 0.04,
    alignItems: 'center',
    borderRadius: 10, // Rounded corners
    elevation: 3, // Shadow for 3D effect
  },
});

export default HomeScreen;


const Card = ({ price, imageUrl, onPress }: { price: number, imageUrl: string; onPress: () => void; }) => {
  return (
    <View
      style={{
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: 20
      }}
    >
      <Image
        source={{ uri: imageUrl }}
        resizeMode="cover"
        style={{
          position: 'absolute',
          top: 0, left: 0,
          bottom: 0,
          right: 0,
        }}
      />
      <View style={{ flex: 1 }} />
      <View style={{

        position: 'absolute',
        left: 16,
        top: 16,
        paddingHorizontal: 16,
        paddingVertical: 10,
        backgroundColor: "rgba(0,0,0,0.45)",
        borderRadius: 100
      }}>
        <Text style={{
          fontSize: 14,
          fontWeight: "600",
          color: "#fff"
        }}
        >
          ${price}
        </Text>
      </View>
    </View >
  )
}
