import { createStackNavigator } from "@react-navigation/stack"
import CartScreen from "../../screens/CartScreen";

const Stack = createStackNavigator()
const CartStack = () => {

  return (
    <Stack.Navigator initialRouteName="CartScreen">
      <Stack.Screen name="CartScreen" component={CartScreen} />
    </Stack.Navigator>
  )
}


export default CartStack;