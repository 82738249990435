import React, { useMemo } from 'react';
import { DefaultTheme, NavigationContainer, Theme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/react-hooks';
import RootNavigator from './app/navigators/RootNavigator';
import { StyleSheet, View } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { StatusBar } from 'expo-status-bar';
import TabsNavigator from './app/navigators/TabsNavigator';

const client = new ApolloClient({
  uri: 'http://localhost:3000/graphql',
  cache: new InMemoryCache()
});

const Stack = createStackNavigator();

const nativationOptions = { title: '' }; // This will remove the default title


const App: React.FC = () => {
  const theme: Theme = useMemo(() => ({
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: "#f5f5f5",
      text: "#191919",
      border: "#D9D9D9",
      primary: "#191919"
    }
  }), []);

  return (
    <SafeAreaProvider style={{ flex: 1 }}>
      <GestureHandlerRootView style={styles.container}>
        <ApolloProvider client={client}>
          <NavigationContainer theme={theme}>
            <BottomSheetModalProvider>
              <RootNavigator />
            </BottomSheetModalProvider>
            <StatusBar style="dark" />
          </NavigationContainer>
        </ApolloProvider>
      </GestureHandlerRootView>
    </SafeAreaProvider>
  );
}

export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  }
})
