import React, { useState, useEffect, useRef } from 'react';
import { View, TextInput, Button, StyleSheet, Alert, Text, Animated, Dimensions } from 'react-native';
const placeholderImage = require('../../assets/purseSample.jpeg');

const SignUpScreen: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [orientation, setOrientation] = useState('portrait');

  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(
      fadeAnim,
      {
        toValue: 1,
        duration: 2000,
        useNativeDriver: true
      }
    ).start();
  }, [fadeAnim]);

  useEffect(() => {
    const updateLayout = () => {
      const { width, height } = Dimensions.get('window');
      setOrientation(width < height ? 'portrait' : 'landscape');
    };

    Dimensions.addEventListener('change', updateLayout);

    return () => {
      Dimensions.removeEventListener('change', updateLayout);
    };
  }, []);

  const submitForm = () => {
    if (!name || !email || !phoneNumber) {
      Alert.alert("Missing Fields", "Please fill all required fields.");
      return;
    }
    // TODO: Handle form submission here
  }

  return (
    <View style={[styles.container, orientation === 'portrait' ? styles.portraitContainer : styles.landscapeContainer]}>
      <View style={styles.formContainer}>
        <Text style={styles.title}>Sign Up</Text>
        <TextInput style={styles.input} placeholder="Name" value={name} onChangeText={setName} />
        <TextInput style={styles.input} placeholder="Email" value={email} onChangeText={setEmail} />
        <TextInput style={styles.input} placeholder="Phone Number" value={phoneNumber} onChangeText={setPhoneNumber} />
        <TextInput style={styles.input} placeholder="Date of Birth (optional)" value={dob} onChangeText={setDob} />
        <TextInput style={styles.input} placeholder="Gender (optional)" value={gender} onChangeText={setGender} />
        <Button title="Submit" onPress={submitForm} />
      </View>
      <Animated.View style={[styles.imageContainer, { opacity: fadeAnim }]}>
        <Animated.Image source={{uri: placeholderImage}} style={styles.image} />
      </Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  portraitContainer: {
    flexDirection: 'column',
  },
  landscapeContainer: {
    flexDirection: 'row',
  },
  formContainer: {
    flex: 1,
    padding: 20,
  },
  imageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    paddingLeft: 10,
    marginBottom: 10,
  },
});



export default SignUpScreen;