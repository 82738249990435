import { createStackNavigator } from "@react-navigation/stack"
import HomeScreen from "../../screens/HomeScreen";

const Stack = createStackNavigator()
const HomeStack = () => {

  return (
    <Stack.Navigator
      initialRouteName="HomeScreen"
      screenOptions={{
        headerShown: true,
      }}
    >
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
    </Stack.Navigator >
  )
}


export default HomeStack;