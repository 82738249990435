import { View, Text } from 'react-native'
import React from 'react'
import { NativeStackScreenProps, createNativeStackNavigator } from '@react-navigation/native-stack'
import FeedScreen from '../screens/FeedScreen';
import LeftHeader from '../components/LeftHeaderComponent';
import RightHeader from '../components/RightHeaderComponent';
import DetailsScreen from '../screens/DetailsScreen';
import HomeScreen from '../screens/HomeScreen';
import CategoryScreen from '../screens/CategoryScreen';
import ProductScreen from '../screens/ProductScreen';
import ProductDetailScreen from '../screens/ProductDetailScreen';
import CartScreen from '../screens/CartScreen';
import SignUpScreen from '../screens/SignUpScreen';
import { NavigatorScreenParams } from '@react-navigation/native';
import TabsNavigator, { TabsStackParamList } from './TabsNavigator';


export type RootStackParamList = {
  TabsStack: NavigatorScreenParams<TabsStackParamList>;
  Home: undefined;
  Feed: undefined;
  Details: undefined;
  Category: undefined;
  Product: undefined;
  ProductDetail: undefined;
  Cart: undefined;
  SignUp: undefined;
}

const RootStack = createNativeStackNavigator<RootStackParamList>();
const nativationOptions = { title: '' }; // This will remove the default title

export type RootStackScreenProps<T extends keyof RootStackParamList> =
  NativeStackScreenProps<RootStackParamList, T>


const RootNavigator = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        headerShown: true,
        // headerLeft: () => <LeftHeader />,
        // headerRight: () => <RightHeader />
      }}
    >
      <RootStack.Screen
        name="TabsStack"
        component={TabsNavigator}
        options={{
          headerShown: false,
        }} />
      <RootStack.Screen name="Feed" component={FeedScreen} />
      <RootStack.Screen name="Details" component={DetailsScreen} />
      {/* <RootStack.Screen
        name="Home"
        component={HomeScreen}
        options={nativationOptions}
      />
      <RootStack.Screen name="Category" component={CategoryScreen} options={nativationOptions}
      />
      <RootStack.Screen name="Product" component={ProductScreen} options={nativationOptions}
      />
      <RootStack.Screen name="ProductDetail" component={ProductDetailScreen} options={nativationOptions}
      />
      <RootStack.Screen name="Cart" component={CartScreen} options={nativationOptions}
      />
      <RootStack.Screen name="SignUp" component={SignUpScreen} options={nativationOptions}
      /> */}
    </RootStack.Navigator>
  )
}
export default RootNavigator